export const setQueryParam = (
  param: string,
  value: string | number | null | undefined
) => {
  const params = new URLSearchParams(window.location.search);
  params.set(param, String(value));
  // window.location.search = params.toString();
  // @ts-ignore
  window.history.replaceState(null, null, "?" + params.toString());
};

export const getQueryParam = (param: string): string | null | undefined => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

export const withoutQuery = (url: string, ...params: string[]) => {
  const r = new URL(url);
  for (const p of params) {
    r.searchParams.delete(p);
  }
  return r.href;
};
